import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { FormFileField } from "components";
import tolgee from "services/translation";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
export default function CompanyPolicies(_a) {
    var handleUploadFile = _a.handleUploadFile, handleRemoveFile = _a.handleRemoveFile;
    var watch = useFormContext().watch;
    var privacyPolicy = watch("privacy_policy");
    var privacyPolicyUrl = watch("privacy_policy_url");
    var cookie = watch("cookie_policy");
    var cookieUrl = watch("cookie_policy_url");
    return (_jsx("div", { className: "p-2 w-full", children: _jsxs("div", { className: "flex flex-row items-center justify-between gap-6", children: [_jsxs(Stack, { spacing: 2, children: [_jsxs("div", { children: [_jsxs("div", { className: "font-bold", children: [tolgee.t("clp.privacy_policy_heading"), ":"] }), tolgee.t("clp.privacy_policy")] }), _jsxs("div", { className: "flex items-start w-full space-x-2", children: [_jsx(FormFileField, { label: tolgee.t({ key: "clp.choose_file" }), className: "mt-2", value: (privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.name) && [{ name: privacyPolicy.name }], onChange: function (files) { return handleUploadFile(files[0], "privacy"); }, fullWidth: true }), privacyPolicyUrl && (_jsxs(_Fragment, { children: [_jsx(FileDownloadPreview, { icon: "file", url: privacyPolicyUrl, fileName: "Privacy Policy" }), _jsx(IconButton, { onClick: function () { return handleRemoveFile("PRIVACY_POLICY"); }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "xmark" }) })] }))] })] }), _jsxs(Stack, { spacing: 2, children: [_jsxs("div", { children: [_jsxs("div", { className: "font-bold", children: [tolgee.t("clp.cookie_policy_heading"), ":"] }), tolgee.t("clp.cookies_policy")] }), _jsxs("div", { className: "flex items-start w-full space-x-2", children: [_jsx(FormFileField, { label: tolgee.t({ key: "clp.choose_file" }), className: "mt-2", value: (cookie === null || cookie === void 0 ? void 0 : cookie.name) && [{ name: cookie.name }], onChange: function (files) { return handleUploadFile(files[0], "cookie"); }, fullWidth: true }), cookieUrl && (_jsxs(_Fragment, { children: [_jsx(FileDownloadPreview, { icon: "file", url: cookieUrl, fileName: "Cookie Policy" }), _jsx(IconButton, { onClick: function () { return handleRemoveFile("COOKIE_POLICY"); }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "xmark" }) })] }))] })] })] }) }));
}
