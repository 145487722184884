export var emptyContact = {
    organization: "",
    contact_points: [],
    addresses: [],
    name: "",
    label: "",
    salutation: null,
    comment: null,
};
export var emptyActor = {
    acting_as: null,
    contact: emptyContact,
};
