var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useCallback } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { BaseButton, BaseSelectField } from "components";
import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import { availableCurrenciesDict } from "const/billing";
import AmountField from "components/Input/TextField/AmountField";
import { handleError } from "services/api/error";
var defaultValues = {
    invoice_number: "",
    amount: null,
    currency: "EUR",
    transfer_number: "",
    reference: "",
    paid_at: moment().subtract(1, "days").startOf("day").format(), // Initially yesterday's date at midnight
};
export default function Transferwise() {
    var _this = this;
    var _a, _b;
    var dispatch = useDispatch();
    var _c = useForm({
        defaultValues: defaultValues,
    }), register = _c.register, watch = _c.watch, setValue = _c.setValue, trigger = _c.trigger, _d = _c.formState, errors = _d.errors, isValid = _d.isValid;
    var form = watch();
    var amount = watch("amount");
    var currency = watch("currency");
    var paidAt = watch("paid_at");
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    trigger();
                    if (!isValid) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.billing.settleWisePayment(form)];
                case 2:
                    _a.sent();
                    dispatch(notify({
                        message: "Invoice Settled!",
                        type: "SUCCESS",
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    handleError(e_1);
                    dispatch(notify({
                        message: e_1.message,
                        type: "ERROR",
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAmountChange = useCallback(function (val) {
        setValue("amount", val);
    }, [setValue]);
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "transferwise-page", children: _jsxs("div", { className: "box-outerlayout flex flex-col space-y-2 pb-4", children: [_jsx("h1", { children: "Track Transferwise Payment" }), _jsx(TextField, __assign({ label: _jsx(T, { keyName: "billing.invoice_number" }), error: !!(errors === null || errors === void 0 ? void 0 : errors.invoice_number), helperText: (_a = errors === null || errors === void 0 ? void 0 : errors.invoice_number) === null || _a === void 0 ? void 0 : _a.message, "data-testid": "input-invoice-number", required: true }, register("invoice_number", {
                    validate: {
                        minLength: function (e) {
                            return "".concat(e).length >= 10 || "Invoice number should look like 'XXXXXX-0000'";
                        },
                    },
                }))), _jsxs("div", { className: "flex space-x-2", children: [_jsx(AmountField, { "data-testid": "input-amount", value: amount, onChange: handleAmountChange, required: true }), _jsx("div", { className: "w-24", children: _jsx(BaseSelectField, { label: tolgee.t({
                                    key: "billing.currency",
                                }), state: [currency, function (val) { return setValue("currency", val); }], options: availableCurrenciesDict }) })] }), _jsx(TextField, __assign({ label: _jsx(T, { keyName: "billing.wise.transfer_number" }), error: !!(errors === null || errors === void 0 ? void 0 : errors.transfer_number), helperText: (_b = errors === null || errors === void 0 ? void 0 : errors.transfer_number) === null || _b === void 0 ? void 0 : _b.message, "data-testid": "input-transfer-number", required: true }, register("transfer_number", {
                    required: "Please enter a valid transfer number.",
                }))), _jsx("div", { "data-testid": "input-paid-at", children: _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, __assign({ maxDate: moment(), format: "DD/MM/YYYY", label: tolgee.t({ key: "billing.paid_at" }), className: "w-full", value: moment(paidAt) }, register("paid_at"), { onChange: function (date) { return setValue("paid_at", date.startOf("day").format()); } })) }) }), _jsx(TextField, __assign({ label: _jsx(T, { keyName: "billing.wise.reference" }) }, register("reference"))), _jsx("div", { className: "w-fit self-end mt-4", children: _jsx(BaseButton, { testid: "submit", onClick: function () { return onSubmit(); }, children: _jsx(T, { keyName: "generic.submit_payment_details" }) }) })] }) }));
}
