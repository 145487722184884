import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import tolgee from "services/translation";
import Info from "pages/Client/RepPages/DSA/Components/Info";
import PublicInformation from "pages/Client/RepPages/DSA/Components/PublicInformation";
import Certificate from "pages/Client/RepPages/DSA/Components/Certificate";
import Location from "pages/Client/RepPages/DSA/Components/Location";
export default function DsaRepresentation() {
    useEffect(function () {
        document.title = tolgee.t({
            key: "dsa.dsa_rep",
        });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsx("h1", { children: tolgee.t("dsa.title") }), _jsx(Info, {}), _jsx(PublicInformation, {}), _jsx(Certificate, {}), _jsx(Location, {})] }));
}
