var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { faker } from "faker";
import clsx from "clsx";
import { DEV_ENV } from "const/env";
import { api } from "services";
import { BaseButton, ProgressBar } from "components";
import { jurisdictionDict } from "const";
import CustomizedStepper from "components/Navigation/Stepper";
import RightsForm from "pages/Public/Dsr/Ccpa/Step1/RightsForm";
import AdditionalInfoForm from "pages/Public/Dsr/Ccpa/Step3/AdditionalInfoForm";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import IdentificationForm from "pages/Public/Dsr/Ccpa/Step2/IdentificationForm";
import { useAppDispatch, useAppSelector } from "store";
import { setError } from "store/app";
import tolgee from "services/translation";
import { ccpaFormEmptyValues, ccpaFormInitialValues, } from "pages/Public/Dsr/Ccpa/ccpaRequestObject";
import { useDebounce } from "helpers/hooks";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { validateEmail } from "helpers/validate";
import { handleError } from "services/api/error";
var steps = ["type", "identification", "send"];
export default function CcpaRequestForm(_a) {
    var _this = this;
    var step = _a.step;
    var _b = useState(DEV_ENV), residentOfCalifornia = _b[0], setResidentOfCalifornia = _b[1];
    var version = useAppSelector(function (state) { return state.app; }).version;
    var publicId = useParams().publicId;
    var searchParams = useSearchParams()[0];
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _c = useState("type"), uiStep = _c[0], setUiStep = _c[1];
    var _d = useState({}), businessState = _d[0], setBusinessState = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(false), storageLoaded = _f[0], setStorageLoaded = _f[1];
    var _g = useState(false), displaySuccessMessage = _g[0], setDisplaySuccessMessage = _g[1];
    var _h = useState(""), additionalInfo = _h[0], setAdditionalInfo = _h[1];
    var _j = useState({}), dsrCcpaCase = _j[0], setDsrCcpaCase = _j[1];
    var _k = useState({}), passwordVisibility = _k[0], setPasswordVisibility = _k[1];
    var _l = useState({
        required_inputs: [],
        document_upload: false,
    }), identificationData = _l[0], setIdentificationData = _l[1];
    var methods = useForm({ values: ccpaFormEmptyValues });
    var watch = methods.watch, trigger = methods.trigger, getValues = methods.getValues, register = methods.register, reset = methods.reset, setValue = methods.setValue;
    var request_privacy_related = watch("request_privacy_related");
    var form = watch();
    var acting_for = watch("actors.1.acting_for");
    var debouncedValue = useDebounce(JSON.stringify({ form: __assign(__assign({}, form), { files: [] }), businessId: publicId }), 2000);
    var submitted = useRef(false);
    useEffect(function () {
        function testDsrForm() {
            return __awaiter(this, void 0, void 0, function () {
                var res, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, api.clp.getSettings(publicId)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            handleError(e_1);
                            return [2 /*return*/];
                        case 3:
                            if (!res.data.config.dsr_form) {
                                dispatch(setError("404"));
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        testDsrForm();
    }, [dispatch, publicId]);
    var handleBack = useCallback(function () {
        switch (step) {
            case "identification":
                navigate({ pathname: "/dsr/".concat(publicId, "/ccpa"), search: searchParams.toString() });
                break;
            case "send":
                navigate({
                    pathname: "/dsr/".concat(publicId, "/ccpa/identification"),
                    search: searchParams.toString(),
                });
                break;
            default:
                break;
        }
    }, [navigate, publicId, searchParams, step]);
    var checkStep = useCallback(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (step_) {
            if (step_ === void 0) { step_ = step; }
            return __generator(this, function (_a) {
                switch (step_) {
                    case "type":
                        return [2 /*return*/, trigger(["dsrs"])];
                    case "identification":
                        return [2 /*return*/, trigger([
                                "actors",
                                "".concat(identificationData.document_upload || (acting_for && acting_for !== "MYSELF") ? "files" : null),
                                "identifiable_info",
                            ])];
                    case "send":
                        return [2 /*return*/, trigger()];
                    default:
                        return [2 /*return*/, true];
                }
                return [2 /*return*/];
            });
        });
    }, [acting_for, identificationData === null || identificationData === void 0 ? void 0 : identificationData.document_upload, step, trigger]);
    var shouldButtonDisabled = useCallback(function () {
        var disableRequestType = form.dsrs.every(function (dsr, index) { return index === 1 || !dsr.checked; }) &&
            form.dsrs[1].inquiries.every(function (dsr) { return !dsr.checked; });
        if (uiStep === "send" && !request_privacy_related) {
            return true;
        }
        if (uiStep === "type" && disableRequestType) {
            return true;
        }
        if (uiStep === "identification" && !residentOfCalifornia) {
            return true;
        }
        return false;
    }, [form.dsrs, request_privacy_related, residentOfCalifornia, uiStep]);
    var fetchCustomerFields = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var formValue, dsrTypes, res, e_2, sortedInputs, values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formValue = getValues();
                    dsrTypes = formValue.dsrs.filter(function (item) { return item.checked; }).map(function (item) { return item.type; });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.ccpa.getDsrConfigForCaseType(publicId, dsrTypes)];
                case 2:
                    res = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 4:
                    res = res.data;
                    sortedInputs = res.required_inputs.sort(function (a, b) {
                        if (a === "email") {
                            return -1;
                        }
                        if (b === "email") {
                            return 1;
                        }
                        if (a === "name") {
                            return -1;
                        }
                        if (b === "name") {
                            return 1;
                        }
                        return 0;
                    });
                    values = getValues();
                    sortedInputs.forEach(function (field) {
                        var _a, _b, _c, _d;
                        if (field === "address") {
                            register("identifiable_info.address.postal", {
                                validate: {
                                    minLength: function (e) { return (e && "".concat(e).length > 0) || "Can't be empty"; },
                                },
                            });
                            register("identifiable_info.address.city", {
                                validate: {
                                    minLength: function (e) { return (e && "".concat(e).length > 0) || "Can't be empty"; },
                                },
                            });
                            register("identifiable_info.address.street", {
                                validate: {
                                    minLength: function (e) { return (e && "".concat(e).length > 0) || "Can't be empty"; },
                                },
                            });
                            register("identifiable_info.address.country_iso", {
                                validate: {
                                    minLength: function (e) { return (e && "".concat(e).length > 0) || "Can't be empty"; },
                                },
                            });
                            var initialAddress = {
                                street: ((_a = values.identifiable_info.address) === null || _a === void 0 ? void 0 : _a.street) || faker.location.street(),
                                postal: ((_b = values.identifiable_info.address) === null || _b === void 0 ? void 0 : _b.postal) || faker.location.zipCode("#####"),
                                city: ((_c = values.identifiable_info.address) === null || _c === void 0 ? void 0 : _c.city) || faker.location.city(),
                                country_iso: ((_d = values.identifiable_info.address) === null || _d === void 0 ? void 0 : _d.country_iso) || "AT",
                            };
                            setValue("identifiable_info.address", initialAddress);
                        }
                        else if (field === "email") {
                            register("identifiable_info.email", {
                                validate: {
                                    required: function (e) { return validateEmail(e) || "Please enter a valid email."; },
                                },
                            });
                            setValue("identifiable_info.email", values.identifiable_info.email || faker.internet.exampleEmail());
                        }
                        else {
                            register("identifiable_info.".concat(field), {
                                validate: {
                                    minLength: function (e) { return (e && "".concat(e).length > 0) || "Can't be empty"; },
                                },
                            });
                            setValue("identifiable_info.".concat(field), values.identifiable_info[field] || faker.lorem.words(2));
                        }
                    });
                    setIdentificationData({
                        document_upload: res.document_upload,
                        required_inputs: sortedInputs,
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [getValues, publicId, register, setValue]);
    useEffect(function () {
        function proceedUiStep() {
            return __awaiter(this, void 0, void 0, function () {
                var currentStep, currentUiStep, allowProceeding;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            currentStep = steps.indexOf(step);
                            currentUiStep = steps.indexOf(uiStep);
                            if (!(currentUiStep < currentStep)) return [3 /*break*/, 6];
                            return [4 /*yield*/, checkStep(uiStep)];
                        case 1:
                            allowProceeding = (_a.sent()) && !shouldButtonDisabled();
                            if (!allowProceeding) return [3 /*break*/, 4];
                            if (!(uiStep === "type")) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetchCustomerFields()];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            setUiStep(steps[currentUiStep + 1]);
                            return [3 /*break*/, 5];
                        case 4:
                            handleBack();
                            _a.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            if (currentUiStep > currentStep) {
                                setUiStep(steps[currentUiStep - 1]);
                            }
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        }
        if (!loading) {
            proceedUiStep();
        }
    }, [step, uiStep, loading, checkStep, shouldButtonDisabled, handleBack, fetchCustomerFields]);
    useEffect(function () {
        if (!submitted.current &&
            !loading &&
            debouncedValue &&
            Object.keys(JSON.parse(debouncedValue).form.identifiable_info).length !== 0) {
            localStorage.setItem("ccpa_form", debouncedValue);
            localStorage.setItem("ccpa_form_date", JSON.stringify(new Date()));
            localStorage.setItem("app_version", version);
        }
    }, [debouncedValue, loading, storageLoaded, getValues, version]);
    useEffect(function () {
        function getBusiness() {
            return __awaiter(this, void 0, void 0, function () {
                var res, e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, api.dsr.getDsrBusiness(publicId)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_3 = _a.sent();
                            handleError(e_3, false, true);
                            return [2 /*return*/];
                        case 3:
                            if (!res.data.jurisdictions.includes("dsr_cal")) {
                                dispatch(setError("404"));
                                return [2 /*return*/];
                            }
                            setBusinessState(res.data);
                            document.title = "".concat(res.data.company_name, ": CCPA");
                            return [2 /*return*/];
                    }
                });
            });
        }
        function loadLocalStorage() {
            var localForm = JSON.parse(localStorage.getItem("ccpa_form") || "{}");
            var date = JSON.parse(localStorage.getItem("ccpa_form_date") || "{}");
            var localAppVersion = localStorage.getItem("app_version");
            if (localForm.businessId === publicId &&
                localAppVersion === version &&
                Date.now() - new Date(date).getTime() <= 24 * 60 * 60 * 1000) {
                reset(localForm.form);
            }
            else {
                var initialValues = ccpaFormInitialValues;
                if (DEV_ENV) {
                    initialValues.dsrs[0].checked = true;
                    initialValues.dsrs[0].inquiries[0].answer = faker.lorem.words(20);
                }
                reset(ccpaFormInitialValues);
                localStorage.removeItem("ccpa_form");
                localStorage.removeItem("ccpa_form_date");
            }
            setStorageLoaded(true);
        }
        function processValues() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            loadLocalStorage();
                            return [4 /*yield*/, getBusiness()];
                        case 1:
                            _a.sent();
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
        processValues();
    }, [publicId, dispatch, reset, register, setValue, getValues, version]);
    var handleContinue = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, checkStep()];
                case 1:
                    if (!_b.sent()) return [3 /*break*/, 6];
                    _a = step;
                    switch (_a) {
                        case "type": return [3 /*break*/, 2];
                        case "identification": return [3 /*break*/, 4];
                        case "send": return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 5];
                case 2: return [4 /*yield*/, fetchCustomerFields()];
                case 3:
                    _b.sent();
                    navigate({
                        pathname: "/dsr/".concat(publicId, "/ccpa/identification"),
                        search: searchParams.toString(),
                    });
                    return [3 /*break*/, 6];
                case 4:
                    navigate({ pathname: "/dsr/".concat(publicId, "/ccpa/send"), search: searchParams.toString() });
                    return [3 /*break*/, 6];
                case 5: return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var tempValues, checkedDSRs, updatedDsrs, addInfoInquiry, valuesToSubmit, res, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    tempValues = getValues();
                    checkedDSRs = tempValues.dsrs.filter(function (dsr) { return dsr.checked; });
                    updatedDsrs = [];
                    checkedDSRs.forEach(function (item) {
                        if (item.type === "DSR_CCPA_ACCESS") {
                            return item.inquiries.forEach(function (i) {
                                if (i.checked) {
                                    updatedDsrs.push({
                                        type: i.type,
                                        checked: i.checked,
                                        inquiries: [
                                            {
                                                answer: i.answer,
                                                question_i18n: i.question_i18n,
                                            },
                                        ],
                                        extra: item.extra,
                                    });
                                }
                            });
                        }
                        return updatedDsrs.push(item);
                    });
                    addInfoInquiry = {
                        question_i18n: {
                            key: "dsr.additional_info",
                        },
                        answer: additionalInfo,
                    };
                    updatedDsrs.forEach(function (dsr) {
                        if (additionalInfo) {
                            dsr.inquiries.push(addInfoInquiry);
                        }
                    });
                    if (tempValues.actors[1].acting_for === "MYSELF") {
                        tempValues.actors[0].name = tempValues.identifiable_info.name;
                        tempValues.actors[0].email = tempValues.actors[1].email;
                        tempValues.actors.splice(1, 1);
                    }
                    else {
                        tempValues.actors[0].name = tempValues.identifiable_info.name;
                        delete tempValues.actors[0].acting_for;
                    }
                    valuesToSubmit = __assign(__assign({}, tempValues), { dsrs: updatedDsrs, type: "DSR_CCPA" });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, api.ccpa.createDsrCcpaCase(publicId, valuesToSubmit)];
                case 2:
                    res = _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_4 = _a.sent();
                    handleError(e_4);
                    return [2 /*return*/];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5:
                    setDsrCcpaCase(res.data);
                    setDisplaySuccessMessage(true);
                    localStorage.removeItem("ccpa_form");
                    localStorage.removeItem("ccpa_form_date");
                    submitted.current = true;
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClickShowPassword = function (uuid) {
        setPasswordVisibility(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[uuid] = !prevState[uuid], _a)));
        });
    };
    var handleCopyPassword = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, navigator.clipboard.writeText(dsrCcpaCase.ds_password_init)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCopyCaseUrl = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, navigator.clipboard.writeText(dsrCcpaCase.ext_url)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [loading && _jsx(ProgressBar, {}), !loading && displaySuccessMessage && (_jsxs("div", { className: "flex flex-col h-screen p-10", children: [_jsxs("div", { className: "flex items-center justify-end py-2 mb-6", children: [_jsx("span", { className: "text-4xl font-bold text-prighterblue", children: "Prighter" }), _jsx(PrighterLogo, { width: 25, className: "ml-2" })] }), _jsx("div", { className: "flex items-center justify-center grow", children: _jsxs("div", { className: "box-outerlayout p-4", children: [_jsxs("div", { className: "flex text-left items-start justify-between", children: [_jsxs("div", { children: [_jsx("h1", { className: "text-xl text-gray-700", children: _jsx(T, { keyName: "dsr.thank_you_for_your_request" }) }), _jsxs("div", { className: "mt-1 text-sm text-gray-600", children: [_jsx(T, { keyName: "dsr.please_verify_email_address" }), _jsx("br", {}), _jsx(T, { keyName: "dsr.ds_request_success_message" })] })] }), _jsx("img", { src: jurisdictionDict.dsr_cal.jurisdiction_lock_icon_url, alt: "ccpa-logo", className: "object-contain", width: "24", height: "24" })] }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-4" }), _jsxs("div", { children: [_jsx("h2", { className: "text-base text-left text-gray-700 mt-0", children: _jsx(T, { keyName: "dsr.view_dsr_request" }) }), _jsx("div", { className: "mt-1 text-sm text-gray-600", children: _jsx(T, { keyName: "dsr.dsr_request_description" }) }), _jsx("div", { className: "flex flex-col items-center justify-between gap-4 mt-4", children: _jsxs("div", { className: "flex flex-row items-center justify-between gap-4 w-full", children: [_jsx(TextField, { className: "w-full", value: dsrCcpaCase.ext_url, label: tolgee.t("dsr.dsr_request_url_label"), slotProps: {
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (_jsxs("div", { className: "flex flex-row", children: [_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return handleCopyCaseUrl(); }, edge: "end", children: _jsx(ContentCopyOutlinedIcon, {}) }) }), _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { href: dsrCcpaCase.ext_url, target: "_blank", rel: "noopener noreferrer", edge: "end", children: _jsx(OpenInNewIcon, {}) }) })] })),
                                                            },
                                                        } }), _jsx(TextField, { className: "w-full", value: dsrCcpaCase.ds_password_init, type: passwordVisibility[dsrCcpaCase.cases[0].uuid] ? "text" : "password", label: tolgee.t("dsr.dsr_request_password_label"), slotProps: {
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (_jsxs("div", { className: "flex flex-row", children: [passwordVisibility[dsrCcpaCase.cases[0].uuid] ? (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return handleCopyPassword(); }, edge: "end", children: _jsx(ContentCopyOutlinedIcon, {}) }) })) : (""), _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return handleClickShowPassword(dsrCcpaCase.cases[0].uuid); }, edge: "end", children: passwordVisibility[dsrCcpaCase.cases[0].uuid] ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) })] })),
                                                            },
                                                        } })] }) })] })] }) })] })), !loading && !displaySuccessMessage && businessState.public_id && (_jsxs(RequestFormContainer, { headerState: businessState, children: [_jsxs("div", { className: "flex items-center justify-between gap-2", children: [_jsx("h1", { className: "text-lg md:text-2xl ", children: _jsx(T, { keyName: "ccpa.make_a_privacy_related_request_to", params: { company_name: businessState.company_name } }) }), _jsx("img", { src: jurisdictionDict.dsr_cal.jurisdiction_lock_icon_url, alt: "ccpa-logo", className: "object-contain w-12 h-12 md:w-16 md:h-16", width: "64", height: "64" })] }), _jsx(CustomizedStepper, { currentStep: uiStep }), _jsx(FormProvider, __assign({}, methods, { children: _jsxs(_Fragment, { children: [_jsx("div", { className: clsx({ block: uiStep === "type", hidden: uiStep !== "type" }), children: _jsx(RightsForm, {}) }), _jsx("div", { className: clsx({
                                        block: uiStep === "identification",
                                        hidden: uiStep !== "identification",
                                    }), children: _jsx(IdentificationForm, { identificationData: identificationData, checkboxState: [residentOfCalifornia, setResidentOfCalifornia] }) }), _jsx("div", { className: clsx({ block: uiStep === "send", hidden: uiStep !== "send" }), children: _jsx(AdditionalInfoForm, { data: getValues(), identificationData: identificationData, additionalInfoState: [additionalInfo, setAdditionalInfo] }) })] }) })), _jsxs("div", { className: "flex ".concat(uiStep === "type" ? "justify-end" : "justify-between", " mt-4"), children: [uiStep !== "type" && (_jsx(BaseButton, { onClick: handleBack, children: _jsx(T, { keyName: "generic.back" }) })), _jsx(BaseButton, { disabled: shouldButtonDisabled(), onClick: uiStep === "send" ? handleSubmit : handleContinue, children: uiStep === "send" ? (_jsx(T, { keyName: "generic.submit" })) : (_jsx(T, { keyName: "generic.continue" })) })] })] }))] }));
}
