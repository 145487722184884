import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { landingPageUrl } from "const";
import { BaseTextField } from "components";
import tolgee from "services/translation";
import { useAppSelector } from "store";
export default function Info() {
    var businessId = useParams().businessId;
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var currentBusiness = managed_businesses === null || managed_businesses === void 0 ? void 0 : managed_businesses.find(function (item) { return item.public_id === businessId; });
    var html = "\n    <div>\n      <span>".concat(currentBusiness.company_name, "</span>\n      <span>").concat(tolgee.t("dsa.website_wording_1"), "</span>\n      <a href=").concat(landingPageUrl(businessId), ">").concat(tolgee.t("dsa.digital_governance_portal"), "</a>\n      <span>").concat(tolgee.t("dsa.website_wording_2"), "</span>\n    </div>\n  ");
    var copyHTML = function () {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(html);
    };
    return (_jsx("div", { className: "box-outerlayout pb-6 pt-10 mb-6", "data-testid": "info-section", children: _jsxs("div", { className: "md:p-6 w-full", children: [_jsxs("div", { className: "mb-6", children: [_jsx("h2", { children: tolgee.t("dsa.public_information_title") }), _jsx("div", { children: tolgee.t("dsa.public_information_description") })] }), _jsxs("div", { className: "p-4 mb-8 border rounded-lg border-gray-300 bg-gray-50", children: [_jsx("h3", { children: tolgee.t("dsa.website_wording_title") }), _jsxs("div", { children: [_jsx("span", { children: currentBusiness.company_name }), " ", _jsx("span", { children: tolgee.t("dsa.website_wording_1") }), " ", _jsx("a", { href: landingPageUrl(businessId), children: tolgee.t("dsa.digital_governance_portal") }), " ", _jsx("span", { children: tolgee.t("dsa.website_wording_2") })] })] }), _jsxs("div", { children: [_jsx("h3", { children: tolgee.t("dsa.website_wording_title_html") }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full", children: [_jsx("div", { className: "mb-6", children: _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, disabled: true, value: html }) }), _jsx("div", { className: "flex justify-end mt-4", children: _jsxs(Button, { onClick: function () { return copyHTML(); }, children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: "copy", color: "gray", size: "lg" }), tolgee.t("generic.copy")] }) })] })] })] }) }));
}
