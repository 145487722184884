var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { Link, useParams } from "react-router";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import tolgee from "services/translation";
import { landingPageUrl } from "const";
import { REACT_BASE_ROUTE } from "const/env";
export default function CustomizeCompanyDetails(_a) {
    var _this = this;
    var _b;
    var customSlug = _a.customSlug, handleUpdateSettings = _a.handleUpdateSettings;
    var _c = useFormContext(), register = _c.register, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var businessId = useParams().businessId;
    var _d = useState(false), showSlugInput = _d[0], setShowSlugInput = _d[1];
    return (_jsxs("div", { className: "p-2 w-full", children: [_jsx("div", { className: "mb-8", children: _jsxs(Stack, { spacing: 2, children: [_jsxs("div", { children: [tolgee.t("clp.clp_reachable"), ":"] }), _jsx("span", { children: _jsx(Link, { to: landingPageUrl(businessId), children: landingPageUrl(businessId) }) }), customSlug && (_jsxs("span", { children: [_jsx("a", { href: "/portal/".concat(customSlug), children: "".concat(REACT_BASE_ROUTE, "/portal/").concat(customSlug) }), " ", "(Landing page with slug)"] })), _jsx("div", { className: "self-baseline", children: _jsx(Button, { onClick: function () { return setShowSlugInput(!showSlugInput); }, startIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "edit" }), children: tolgee.t("clp.customize_url") }) }), showSlugInput && (_jsx(TextField, __assign({}, register("slug", {
                            minLength: 4,
                            maxLength: 64,
                            validate: {
                                slugRestrictions: function (value) {
                                    if (!/^[a-zA-Z]/.test(value) || !/^[-_\w]{3,}$/.test(value)) {
                                        return false;
                                    }
                                    if (/[-_]{2,}/.test(value)) {
                                        return false;
                                    }
                                    return !(value.endsWith("-") || value.endsWith("_"));
                                },
                            },
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.slug), helperText: (errors === null || errors === void 0 ? void 0 : errors.slug) ? tolgee.t({ key: "clp.custom_slug_error_message" }) : "", label: tolgee.t({ key: "clp.custom_slug" }), InputProps: {
                                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, handleSubmit(handleUpdateSettings)()];
                                                    case 1:
                                                        _a.sent();
                                                        if (!(errors === null || errors === void 0 ? void 0 : errors.slug)) {
                                                            setShowSlugInput(false);
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }, size: "large", children: _jsx(SaveIcon, { className: "text-prighterblue" }) }) })),
                            } })))] }) }), _jsx("div", { className: "mb-8", children: _jsxs(Stack, { spacing: 2, children: [_jsxs("div", { children: [_jsxs("div", { className: "font-bold", children: [tolgee.t("clp.change_name_heading"), ":"] }), tolgee.t("clp.change_name"), ":"] }), _jsx(TextField, __assign({}, register("trade_name"), { InputLabelProps: { shrink: true }, id: "standard-basic", label: tolgee.t({ key: "generic.name" }) }))] }) }), _jsx("div", { className: "mb-8", children: _jsxs(Stack, { spacing: 2, children: [_jsxs("div", { children: [_jsxs("div", { className: "font-bold", children: [tolgee.t("clp.extra_text_heading"), ":"] }), tolgee.t("clp.extra_text_clp"), ":"] }), _jsx(TextField, __assign({}, register("clp_text", {
                            validate: function (e) {
                                return !e || e.length > 2 || tolgee.t({ key: "clp.clp_text.error" });
                            },
                        }), { error: !!(errors === null || errors === void 0 ? void 0 : errors.clp_text), helperText: (((_b = errors === null || errors === void 0 ? void 0 : errors.clp_text) === null || _b === void 0 ? void 0 : _b.message) || ""), className: "w-full", InputLabelProps: { shrink: true }, label: tolgee.t({ key: "clp.customizable_text" }) }))] }) }), _jsx("div", { className: "flex justify-end", children: _jsx(Button, { onClick: handleSubmit(handleUpdateSettings), children: tolgee.t("generic.save") }) })] }));
}
