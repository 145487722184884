import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { BaseButton } from "components/Button";
import { BaseModal } from "components/DataDisplay/Modal";
import tolgee from "services/translation";
export default function UnauthorizedAccessModal(props) {
    var modalState = props.modalState, setPassword = props.setPassword, passwordErrorState = props.passwordErrorState, _a = props.submit, submit = _a === void 0 ? function () { } : _a;
    var open = modalState[0], setOpen = modalState[1];
    var _b = useState(false), isPasswordVisible = _b[0], setIsPasswordVisible = _b[1];
    var passwordError = passwordErrorState[0], setPasswordError = passwordErrorState[1];
    return (_jsxs(BaseModal, { modalState: [open, setOpen], children: [_jsx("h2", { children: tolgee.t("unauthorizedAccessModal.title") }), _jsx("p", { children: tolgee.t("unauthorizedAccessModal.description") }), _jsx("div", { className: "mb-4", children: _jsx(TextField, { error: !!passwordError, className: "w-full", type: isPasswordVisible ? "text" : "password", label: tolgee.t("unauthorizedAccessModal.inputLabel"), onChange: function (event) { return [setPassword(event.target.value), setPasswordError(false)]; }, onKeyDown: submit, slotProps: {
                        input: {
                            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setIsPasswordVisible(!isPasswordVisible); }, edge: "end", children: isPasswordVisible ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) })),
                        },
                    } }) }), _jsx("div", { className: "flex justify-center", children: _jsx(BaseButton, { onClick: submit, children: tolgee.t("unauthorizedAccessModal.button") }) })] }));
}
