var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter } from "helpers/general";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { handleError } from "services/api/error";
import { RepLocationType } from "types/general";
export default function Snippet() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _a = useState([]), locations = _a[0], setLocations = _a[1];
    var _b = useForm({ defaultValues: { selectedLocation: "" } }), watch = _b.watch, setValue = _b.setValue;
    var selectedLocation = watch("selectedLocation");
    var fetchLocationsData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, sorted_locations, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.getRepLocations(RepLocationType.DSA)];
                case 1:
                    res = _a.sent();
                    sorted_locations = Object.keys(res.data).sort(function (a, b) { return a.localeCompare(b); });
                    setLocations(sorted_locations);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleError(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var fetchCurrentLocation = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.getRepLocation(businessId, RepLocationType.DSA)];
                case 1:
                    res = _a.sent();
                    setValue("selectedLocation", res.data);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [businessId, setValue]);
    useEffect(function () {
        fetchLocationsData();
        fetchCurrentLocation();
    }, [fetchCurrentLocation, fetchLocationsData]);
    var handleSetLocation = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.business.updateRepLocation(businessId, {
                            location: selectedLocation,
                            type: RepLocationType.DSA,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleError(e_3);
                    return [3 /*break*/, 3];
                case 3:
                    dispatch(notify({
                        message: tolgee.t({
                            key: "dsa.location_updated",
                        }),
                        type: "SUCCESS",
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "box-outerlayout pb-6 pt-10 mt-6", children: _jsxs("div", { className: "md:p-6 w-full", children: [_jsx("h2", { children: tolgee.t("dsa.location_title") }), _jsxs("div", { children: [_jsx(TextField, { className: "w-full", select: true, value: selectedLocation, onChange: function (event) {
                                var _a;
                                setValue("selectedLocation", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                            }, label: tolgee.t({
                                key: "dsa.location_label",
                            }), children: locations.map(function (location) { return (_jsx(MenuItem, { value: location, children: capitalizeFirstLetter(location) }, location)); }) }), _jsx("div", { className: "flex justify-end mt-6", children: _jsx(Button, { onClick: handleSetLocation, children: tolgee.t("dsa.set_address") }) })] })] }) }));
}
