var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { fetchCaseData } from "store/thunks";
import { useCase } from "pages/Client/Case/CaseContext";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { CommentTypeEnum } from "types/case";
import { handleError } from "services/api/error";
import { ClosedAsEnum } from "types/dsr/main";
var defaultValues = {
    subject: "",
    content: "",
};
export default function DeclineFraudulentButton(props) {
    var _this = this;
    var buttonTestId = props.buttonTestId, modalButtonTestId = props.modalButtonTestId, label = props.label, labelSubmit = props.labelSubmit, disabled = props.disabled, declineType = props.declineType;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var appDispatch = useDispatch();
    var _b = useState(false), openCommentModal = _b[0], setOpenCommentModal = _b[1];
    var _c = useState(false), openPreviewModal = _c[0], setOpenPreviewModal = _c[1];
    var _d = useForm({
        defaultValues: defaultValues,
    }), register = _d.register, reset = _d.reset, watch = _d.watch, setValue = _d.setValue, getValues = _d.getValues;
    var message = watch("content");
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var res, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, api.ccpa.getDeclinePreview(state.case.uuid, declineType)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            handleError(e_1);
                            return [2 /*return*/];
                        case 3:
                            reset({
                                subject: res.data.subject,
                                content: res.data.content,
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (openPreviewModal) {
            fetchData();
        }
    }, [declineType, openPreviewModal, reset, state.case.uuid]);
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.authCase.comment(state.case.uuid, {
                            text: message,
                            type: CommentTypeEnum.FRAUDULENT_EXPLANATION,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [2 /*return*/];
                case 3:
                    reset(defaultValues);
                    setOpenCommentModal(false);
                    setOpenPreviewModal(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var generateClosedAsType = function () {
        switch (declineType) {
            case "IDENTIFICATION":
                return ClosedAsEnum.DSR_IDENTIFICATION_FAILED;
            case "RESIDENCY":
                return ClosedAsEnum.DSR_LOCATION_FAILED;
            default:
                return ClosedAsEnum.GENERIC_FRAUD;
        }
    };
    var handleDecline = function () { return __awaiter(_this, void 0, void 0, function () {
        var closedAs, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    closedAs = generateClosedAsType();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.authCase.declineCase(state.case.uuid, __assign(__assign({}, getValues()), { files: [], closed_as: closedAs }))];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    handleError(e_3);
                    return [2 /*return*/];
                case 4:
                    appDispatch(notify({
                        message: tolgee.t({
                            key: "dsr.decline_success",
                        }),
                        type: "SUCCESS",
                    }));
                    reset(defaultValues);
                    fetchCaseData()(dispatch, state.case.uuid);
                    setOpenPreviewModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-testid": buttonTestId, onClick: function () { return setOpenCommentModal(true); }, sx: {
                    backgroundColor: "#C7102E",
                    "&:hover": { backgroundColor: "#980c23" },
                }, disabled: disabled, children: tolgee.t({
                    key: "".concat(label),
                }) }), _jsx(BaseModal, { modalState: [openCommentModal, setOpenCommentModal], children: _jsxs("div", { className: "w-[64rem] space-y-4", children: [_jsx("h3", { className: "text-center m-0", children: _jsx(T, { keyName: "dsr.ccpa.decline_as_fraudulent" }) }), _jsx("p", { children: _jsx(T, { keyName: "dsr.ccpa.decline_as_fraudulent_description" }) }), _jsx("div", { className: "flex flex-col space-y-2", children: _jsx(MarkdownEditor, { onChange: function (editorState) {
                                    return setValue("content", editorState, { shouldDirty: true });
                                }, value: watch("content") }) }), _jsx("div", { className: "flex justify-end pt-6", children: _jsx(Button, { "data-testid": modalButtonTestId, sx: {
                                    backgroundColor: "#C7102E",
                                    "&:hover": { backgroundColor: "#980c23" },
                                }, disabled: disabled || message === "", onClick: handleSubmit, children: tolgee.t({
                                    key: "".concat(labelSubmit),
                                }) }) })] }) }), _jsx(BaseModal, { modalState: [openPreviewModal, setOpenPreviewModal], children: _jsxs("div", { className: "w-[64rem] space-y-4", children: [_jsx("h3", { className: "text-center m-0", children: _jsx(T, { keyName: "dsr.decline_request" }) }), _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx(TextField, __assign({ InputLabelProps: { shrink: true }, label: tolgee.t({
                                        key: "generic.subject",
                                    }) }, register("subject"))), _jsx(MarkdownEditor, { onChange: function (editorState) {
                                        return setValue("content", editorState, { shouldDirty: true });
                                    }, value: watch("content") })] }), _jsx("div", { className: "flex justify-end pt-6", children: _jsx(Button, { "data-testid": modalButtonTestId, sx: {
                                    backgroundColor: "#C7102E",
                                    "&:hover": { backgroundColor: "#980c23" },
                                }, disabled: disabled, onClick: handleDecline, children: tolgee.t({
                                    key: "".concat(labelSubmit),
                                }) }) })] }) })] }));
}
